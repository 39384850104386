import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useNavigate, useParams } from "react-router-dom";
import FileUploader from "../../components/FileUploader";
import { Spinner } from "react-bootstrap";
import Navigation from "../../components/Navigation";
import templatePositions from "../../assets/template_positions.xlsx";
import Requirement from "./requirement";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Position = () => {
  const [loading, setLoading] = useState(false);
  const [loadingRequirement, setLoadingRequirement] = useState(false);
  const [dataRequirement, setDataRequirement] = useState([]);
  const [dataPosition, setDataPosition] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [idPosition, setIdPosition] = useState();
  const [search, setSearch] = useState("");
  const [requirementName, setRequirementName] = useState("");
  const [name, setName] = useState("");
  const token = localStorage.getItem("token");
  const { id } = useParams();

  const getDataPosition = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }ac/recruitment/admin/list_positions/${id}/${pageSize ?? 10}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataPosition(res.data.data);
        setTotalData(res.data.totaldata);
        setLoading(false);
      });
  };

  const getDataRequirement = (idPosition, pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${
          process.env.REACT_APP_URL
        }ac/recruitment/admin/list_requirements/${idPosition}/${
          pageSize ?? 10
        }/${pageIndex ?? 1}`,
        {
          headers: { Authorization: "Bearer " + token },
          params: { search: searchIndex },
        }
      )
      .then((res) => {
        setDataRequirement(res.data.data);
        // setTotalData(res.data.totaldata);
        setLoadingRequirement(false);
      });
  };

  useEffect(() => {
    getDataPosition();
  }, []);

  const handleAdd = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/position/add/${id}`,
        { name: name },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/position/edit/${idPosition}`,
        { name: name },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/position/delete/${idPosition}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUploadExcel = (file) => {
    const PPData = new FormData();
    PPData.append("excelFile", file);
    PPData.append("id_company", id);
    axios
      .post(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/upload_position`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        alert("success");
      })
      .catch((err) => {
        alert("failed");
      });
  };

  function copy(text) {
    return new Promise((resolve, reject) => {
      if (
        typeof navigator !== "undefined" &&
        typeof navigator.clipboard !== "undefined" &&
        navigator.permissions !== "undefined"
      ) {
        const type = "text/plain";
        const blob = new Blob([text], { type });
        const data = [new ClipboardItem({ [type]: blob })];
        navigator.permissions
          .query({ name: "clipboard-write" })
          .then((permission) => {
            if (
              permission.state === "granted" ||
              permission.state === "prompt"
            ) {
              navigator.clipboard
                .write(data)
                .then(resolve, reject)
                .catch(reject);
            } else {
              reject(new Error("Permission not granted!"));
            }
          });
      } else if (
        document.queryCommandSupported &&
        document.queryCommandSupported("copy")
      ) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";
        textarea.style.width = "2em";
        textarea.style.height = "2em";
        textarea.style.padding = 0;
        textarea.style.border = "none";
        textarea.style.outline = "none";
        textarea.style.boxShadow = "none";
        textarea.style.background = "transparent";
        document.body.appendChild(textarea);
        textarea.focus();
        textarea.select();
        try {
          document.execCommand("copy");
          document.body.removeChild(textarea);
          resolve();
        } catch (e) {
          document.body.removeChild(textarea);
          reject(e);
        }
      } else {
        reject(
          new Error("None of copying methods are supported by this browser!")
        );
      }
    });
  }

  return (
    <>
      {loading === true ? (
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <div>
          <ToastContainer
            position="top-center"
            autoClose={500}
            hideProgressBar
            theme="colored"
          />
          <Navigation
            link="company-management"
            name="Company Management"
            name2="Position"
          />

          <div className="card border-0 py-2 mx-3 shadow-lg mb-4">
            <div className="card-body">
              <p className="text-blue fw-bold fs-4">Position</p>
              <div className="d-flex justify-content-between">
                <div className="input-group w-50">
                  <input
                    className="form-control input"
                    placeholder="Search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                      getDataPosition(10, 1, e.target.value);
                    }}
                  />
                  <span className="input-group-text">
                    <i class="fas fa-search text-secondary"></i>
                  </span>
                </div>
                <div className="d-flex">
                  <a
                    href={templatePositions}
                    download="template_positions.xlsx"
                  >
                    <div className="btn bg-blue text-white me-2">
                      <i class="fa-solid fa-download me-2"></i>
                      Template
                    </div>
                  </a>
                  <div
                    className="btn bg-blue text-white me-2"
                    onClick={() => setShowAdd(true)}
                  >
                    <i class="fas fa-plus me-2"></i>
                    Add Position
                  </div>
                  <FileUploader handleFile={handleUploadExcel} />
                </div>
              </div>
              {dataPosition.length !== 0 ? (
                <table class="table table-bordered mt-3 rounded rounded-3 overflow-hidden">
                  <thead>
                    <tr className="bg-blue text-white text-center">
                      <th className="fw-normal" width="5%">
                        No.
                      </th>
                      <th className="fw-normal">Name</th>
                      <th className="fw-normal" width="25%">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {dataPosition.map((item) => (
                    <tbody>
                      <tr>
                        <td className="text-center">{item.number}</td>
                        <td>{item.name}</td>
                        <td>
                          <i
                            class="far fa-trash-alt ms-4 pointer text-secondary"
                            onClick={() => {
                              setShowDelete(true);
                              setIdPosition(item.id);
                            }}
                          ></i>
                          <i
                            class="far fa-edit ms-4 pointer text-secondary"
                            onClick={() => {
                              setShowUpdate(true);
                              setName(item.name);
                              setIdPosition(item.id);
                            }}
                          ></i>
                          <i
                            class="fa-solid fa-circle-info pointer text-secondary ms-4"
                            onClick={() => {
                              getDataRequirement(item.id, 10, 1);
                              setRequirementName(item.name);
                              setIdPosition(item.id);
                              setLoadingRequirement(true);
                            }}
                          ></i>
                          <i
                            class="fa-regular fa-copy pointer text-secondary ms-4"
                            onClick={() => {
                              const copied = copy(
                                `https://recruitment.onegml.com/${item.id}/${id}`
                              );
                              if (copied) toast.success("Copied to Clipboard");
                            }}
                          ></i>
                          <a
                            target="_blank"
                            href={`/participant-list/${item.id}`}
                          >
                            <i class="fa-solid fa-arrow-right pointer text-secondary ms-4"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </table>
              ) : (
                <p className="text-center mt-4 mb-2 text-secondary">No Data</p>
              )}
              <PaginationControl
                page={current}
                total={totalData}
                limit={10}
                changePage={(page, size) => {
                  getDataPosition(size, page, search);
                  setCurrent(page);
                }}
              />
            </div>
          </div>

          {requirementName && loadingRequirement === false ? (
            <Requirement
              data={dataRequirement}
              idPosition={idPosition}
              requirementName={requirementName}
            />
          ) : loadingRequirement === true ? (
            <div className="text-center p-3">
              <Spinner animation="border" />
            </div>
          ) : (
            ""
          )}

          {/* for modal */}
          <Modal show={showAdd} onHide={() => setShowAdd(false)}>
            <Modal.Body>
              <p className="fs-4 fw-bold">Add Position</p>
              <div className="mb-3">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  placeholder="name"
                  className="w-100 mt-1 rounded-3 p-2 border form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className="btn bg-blue mx-2 text-white px-4"
                  onClick={handleAdd}
                >
                  OK
                </div>
                <div
                  className="btn bg-blue mx-2 text-white px-4"
                  onClick={() => setShowAdd(false)}
                >
                  Cancel
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showUpdate} onHide={() => setShowUpdate(false)}>
            <Modal.Body>
              <p className="fs-4 fw-bold">Update Position</p>
              <div className="mb-3">
                <label>Name:</label>
                <input
                  type="text"
                  name="name"
                  placeholder="name"
                  className="w-100 mt-1 rounded-3 p-2 border form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="d-flex justify-content-center">
                <div
                  className="btn bg-blue mx-2 text-white px-4"
                  onClick={handleUpdate}
                >
                  OK
                </div>
                <div
                  className="btn bg-blue mx-2 text-white px-4"
                  onClick={() => setShowUpdate(false)}
                >
                  Cancel
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={showDelete} onHide={() => setShowDelete(false)}>
            <Modal.Body>
              <p className="fs-4 fw-bold text-center">Confirmation</p>
              <p className="text-center">
                Are you sure you want to delete this?
              </p>
              <div className="d-flex justify-content-center">
                <div
                  className="btn bg-blue mx-2 text-white px-4"
                  onClick={handleDelete}
                >
                  OK
                </div>
                <div
                  className="btn bg-blue mx-2 text-white px-4"
                  onClick={() => setShowDelete(false)}
                >
                  Cancel
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Position;
