import axios from "axios";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useNavigate, useParams } from "react-router-dom";
import FileUploader from "../../components/FileUploader";
import templateRequirements from "../../assets/template_requirements.xlsx";

const Requirement = ({ data, idPosition, requirementName }) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [idRequirement, setIdRequirement] = useState();
  const [name, setName] = useState("");
  const token = localStorage.getItem("token");

  const handleAdd = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/requirement/add/${idPosition}`,
        { name: name },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUpdate = () => {
    axios
      .put(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/requirement/edit/${idRequirement}`,
        { name: name },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleDelete = () => {
    axios
      .post(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/requirement/delete/${idRequirement}`,
        {},
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        window.location.reload(false);
      })
      .catch((err) => {
        alert(err);
      });
  };

  const handleUploadExcel = (file) => {
    const PPData = new FormData();
    PPData.append("excelFile", file);
    PPData.append("id_position", idPosition);
    axios
      .post(
        `${process.env.REACT_APP_URL}ac/recruitment/admin/upload_requirement`,
        PPData,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        window.location.reload(false);
        alert("success");
      })
      .catch((err) => {
        alert("failed");
      });
  };

  return (
    <>
      <div className="card border-0 py-2 mx-3 shadow-lg mb-4">
        <div className="card-body">
          <p className="text-blue fw-bold fs-4">
            Requirement {requirementName}
          </p>
          <div className="d-flex justify-content-between">
            <div className="input-group w-50">
              <input
                className="form-control input"
                placeholder="Search"
                onChange={(e) => {
                  // setSearch(e.target.value);
                  // getDataRequirement(10, 1, e.target.value);
                }}
              />
              <span className="input-group-text">
                <i class="fas fa-search text-secondary"></i>
              </span>
            </div>
            <div className="d-flex">
              <a
                href={templateRequirements}
                download="template_requirements.xlsx"
              >
                <div className="btn bg-blue text-white me-2">
                  <i class="fa-solid fa-download me-2"></i>
                  Template
                </div>
              </a>
              <div
                className="btn bg-blue text-white me-2"
                onClick={() => setShowAdd(true)}
              >
                <i class="fas fa-plus me-2"></i>
                Add Requirement
              </div>
              <FileUploader handleFile={handleUploadExcel} />
            </div>
          </div>
          {data.length !== 0 ? (
            <table class="table table-bordered mt-3 rounded rounded-3 overflow-hidden">
              <thead>
                <tr className="bg-blue text-white text-center">
                  <th className="fw-normal" width="5%">
                    No.
                  </th>
                  <th className="fw-normal">Name</th>
                  <th className="fw-normal" width="13%">
                    Action
                  </th>
                </tr>
              </thead>
              {data.map((item) => (
                <tbody>
                  <tr>
                    <td className="text-center">{item.number}</td>
                    <td>{item.name}</td>
                    <td>
                      <i
                        class="far fa-trash-alt ms-4 pointer text-secondary"
                        onClick={() => {
                          setShowDelete(true);
                          setIdRequirement(item.id);
                        }}
                      ></i>
                      <i
                        class="far fa-edit ms-4 pointer text-secondary"
                        onClick={() => {
                          setShowUpdate(true);
                          setName(item.name);
                          setIdRequirement(item.id);
                        }}
                      ></i>
                    </td>
                  </tr>
                </tbody>
              ))}
            </table>
          ) : (
            <p className="text-center mt-3 mb-3 text-secondary">No Data</p>
          )}

          {/* <PaginationControl
            page={current}
            total={totalData}
            limit={10}
            changePage={(page, size) => {
              getDataRequirement(size, page, search);
              setCurrent(page);
            }}
          /> */}
        </div>
      </div>

      {/* for modal */}
      <Modal show={showAdd} onHide={() => setShowAdd(false)}>
        <Modal.Body>
          <p className="fs-4 fw-bold">Add Requirement</p>
          <div className="mb-3">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              placeholder="name"
              className="w-100 mt-1 rounded-3 p-2 border form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={handleAdd}
            >
              OK
            </div>
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={() => setShowAdd(false)}
            >
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showUpdate} onHide={() => setShowUpdate(false)}>
        <Modal.Body>
          <p className="fs-4 fw-bold">Update Requirement</p>
          <div className="mb-3">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              placeholder="name"
              className="w-100 mt-1 rounded-3 p-2 border form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={handleUpdate}
            >
              OK
            </div>
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={() => setShowUpdate(false)}
            >
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDelete} onHide={() => setShowDelete(false)}>
        <Modal.Body>
          <p className="fs-4 fw-bold text-center">Confirmation</p>
          <p className="text-center">Are you sure you want to delete this?</p>
          <div className="d-flex justify-content-center">
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={handleDelete}
            >
              OK
            </div>
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={() => setShowDelete(false)}
            >
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Requirement;
