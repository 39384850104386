import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import RequireAuth from "./components/RequireAuth";
import Layout from "./components/Layout";
import Company from "./pages/Company";
import UpdateCompany from "./pages/Company/update";
import AddCompany from "./pages/Company/add";
import Participant from "./pages/Participant";
import Position from "./pages/Position";

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        element={
          <RequireAuth>
            <Layout />
          </RequireAuth>
        }
      >
        <Route path="/" element={<Company />} />
        <Route path="/company-management" element={<Company />} />
        <Route path="/add-company" element={<AddCompany />} />
        <Route path="/update-company/:id" element={<UpdateCompany />} />
        <Route path="/position/:id" element={<Position />} />
        <Route path="/participant-list/:id" element={<Participant />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;
