import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import Navigation from "../../components/Navigation";

const Participant = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataParticipant, setDataParticipant] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [current, setCurrent] = useState(1);
  const [search, setSearch] = useState("");
  const [dataFaculty, setDataFaculty] = useState([]);
  const [dataUniversity, setDataUniversity] = useState([]);
  const [university, setUniversity] = useState();
  const [faculty, setFaculty] = useState();
  const [gender, setGender] = useState("");
  const [age, setAge] = useState();
  const [graduationYear, setGraduationYear] = useState();
  const [experienceYear, setExperienceYear] = useState();
  const [ipkFrom, setIpkFrom] = useState("");
  const [ipkUntil, setIpkUntil] = useState("");
  const [idExport, setIdExport] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const token = localStorage.getItem("token");
  const listId = idExport.join();
  const { id } = useParams();

  const getData = (pageSize, pageIndex, searchIndex) => {
    axios
      .get(
        `${process.env.REACT_APP_URL}ac/recruitment/list/${pageSize ?? 100}/${
          pageIndex ?? 1
        }`,
        {
          headers: { Authorization: "Bearer " + token },
          params: {
            search: searchIndex,
            id_position: id,
            gender: gender,
            age: age,
            id_university_s1: university?.value,
            id_faculty_s1: faculty?.value,
            ipk_s1: ipkFrom ? ipkFrom + ";" + ipkUntil : "",
            graduation_year_s1: graduationYear,
            experience_year: experienceYear,
          },
        }
      )
      .then((res) => {
        setDataParticipant(res.data);
        setTotalData(res.data.totaldata);
        setLoading(false);
      });
  };

  const handleCheckbox = (e) => {
    if (e.target.checked) {
      setIdExport([...idExport, e.target.value]);
    } else {
      setIdExport(idExport.filter((id) => id !== e.target.value));
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);
    setIdExport(dataParticipant?.data?.map((li) => li.id_recruitment));
    if (isCheckAll) {
      setIdExport([]);
    }
  };

  useEffect(() => {
    getData();
    axios
      .get(`${process.env.REACT_APP_URL}ac/recruitment/list_faculty/10/1`, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
      })
      .then((res) => {
        const data = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setDataFaculty(data);
      });
    axios
      .get(`${process.env.REACT_APP_URL}ac/recruitment/list_university/10/1`, {
        headers: {
          Authorization:
            "Basic " +
            btoa(
              `${process.env.REACT_APP_USERNAME}:${process.env.REACT_APP_PASSWORD}`
            ),
        },
      })
      .then((res) => {
        const data = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setDataUniversity(data);
      });
  }, []);

  return (
    <div>
      {loading === true ? (
        <div className="text-center p-5">
          <Spinner animation="border" />
        </div>
      ) : (
        <div>
          <Navigation
            link="company-management"
            name="Company Management"
            name2="Position"
            name3="Participant"
          />
          <div className="m-3 card p-4 border-0 shadow-lg">
            <p className="fw-bold fs-4 text-blue">
              Participant List {dataParticipant.position}
            </p>
            <div className="d-flex justify-content-between">
              <div className="input-group w-65">
                <input
                  className="form-control border"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    getData(100, 1, e.target.value);
                  }}
                />
                <span className="input-group-text">
                  <i class="fas fa-search text-secondary"></i>
                </span>
              </div>
              <div className="d-flex">
                <div
                  className="btn bg-blue text-white px-3 ms-2"
                  onClick={() => {
                    window.location.href = `https://apidls.onegml.com/ac/recruitment/download_recruitment?list_id_recruitment=${listId}&authorization=${token}`;
                  }}
                >
                  Export
                </div>
                <div
                  className="btn bg-blue text-white px-3 ms-2"
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  Filter
                </div>
              </div>
            </div>
            <div className="table-responsive">
              <table class="table table-bordered table-striped table-light mt-3 rounded rounded-3 shadow">
                <thead>
                  <tr className="bg-primary text-white">
                    <th width="6%" className="text-center">
                      Select all
                      <br />
                      <input
                        className="form-check-input input"
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                      />
                    </th>
                    <th width="3%" className="text-center">
                      No.
                    </th>
                    <th>Name</th>
                    <th>
                      Tempat, <br />
                      Tanggal Lahir
                    </th>
                    <th>NIK KTP</th>
                    <th>Umur</th>
                    <th>Gender</th>
                    <th>Alamat</th>
                    <th>Universitas S1</th>
                    <th>Fakultas S1</th>
                    <th>IPK S1</th>
                    <th>
                      Tahun <br />
                      Lulus S1
                    </th>
                    <th>Universitas S2</th>
                    <th>Fakultas S2</th>
                    <th>IPK S2</th>
                    <th>
                      Tahun <br />
                      Lulus S2
                    </th>
                    <th>
                      Pengalaman <br />
                      Kerja
                    </th>
                    <th>TOEFL</th>
                    <th>TOEIC</th>
                    <th>IELTS</th>
                    <th>Phone</th>
                    <th>Email</th>
                  </tr>
                </thead>
                <tbody>
                  {dataParticipant?.data?.map((item) => (
                    <tr>
                      <th className="fw-normal text-center">
                        <input
                          className="form-check-input input"
                          type="checkbox"
                          value={item.id_recruitment}
                          // checked={idExport.includes(item.id_recruitment)}
                          onChange={handleCheckbox}
                        />
                      </th>
                      <th className="fw-normal text-center">{item.number}</th>
                      <th className="fw-normal">{item.name}</th>
                      <th className="fw-normal">{item.ttl}</th>
                      <th className="fw-normal">{item.nik}</th>
                      <th className="fw-normal">{item.age}</th>
                      <th className="fw-normal">{item.gender}</th>
                      <th className="fw-normal">{item.address}</th>
                      <th className="fw-normal">{item.university_s1}</th>
                      <th className="fw-normal">{item.faculty_s1}</th>
                      <th className="fw-normal">{item.ipk_s1}</th>
                      <th className="fw-normal">{item.graduation_year_s1}</th>
                      <th className="fw-normal">{item.university_s2}</th>
                      <th className="fw-normal">{item.faculty_s2}</th>
                      <th className="fw-normal">{item.ipk_s2}</th>
                      <th className="fw-normal">{item.graduation_year_s2}</th>
                      <th className="fw-normal">{item.experience_year}</th>
                      <th className="fw-normal">{item.toefl}</th>
                      <th className="fw-normal">{item.toeic}</th>
                      <th className="fw-normal">{item.ielts}</th>
                      <th className="fw-normal">{item.phone}</th>
                      <th className="fw-normal">{item.email}</th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <PaginationControl
              page={current}
              total={totalData}
              limit={100}
              changePage={(page, size) => {
                getData(size, page, search);
                setCurrent(page);
              }}
            />
          </div>
        </div>
      )}

      {/* for modal */}
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Body>
          <p className="fs-4 fw-bold">Filter</p>
          <div className="my-3">
            <label>Gender:</label>
            <select
              type="text"
              name="id_university"
              className="form-select mt-1 input"
              placeholder="alamat"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" className="text-light">
                Select...
              </option>
              <option value="P">Perempuan</option>
              <option value="L">Laki-Laki</option>
            </select>
          </div>
          <div className="my-3">
            <label>Umur:</label>
            <input
              type="number"
              name="age"
              className="form-control mt-1 input"
              placeholder="umur"
              value={age}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setAge(e.target.value)}
            />
          </div>
          <div className="my-3">
            <label>Tahun Lulus:</label>
            <input
              type="number"
              name="graduationYear"
              className="form-control mt-1 input"
              placeholder="tahun lulus"
              value={graduationYear}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setGraduationYear(e.target.value)}
            />
          </div>
          <div className="my-3">
            <label>Pengalaman Kerja:</label>
            <input
              type="number"
              name="experienceYear"
              className="form-control mt-1 input"
              placeholder="pengalaman kerja"
              value={experienceYear}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setExperienceYear(e.target.value)}
            />
          </div>
          <div className="my-3">
            <label>IPK:</label>
            <div className="d-flex">
              <input
                type="number"
                name="ipk"
                className="form-control mt-1 input me-3"
                placeholder="from"
                value={ipkFrom}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setIpkFrom(e.target.value)}
              />
              <input
                type="number"
                name="ipk"
                className="form-control mt-1 input"
                placeholder="until"
                value={ipkUntil}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => setIpkUntil(e.target.value)}
              />
            </div>
          </div>
          <div className="my-3">
            <label>Fakultas:</label>
            <ReactSelect
              className="basic-single mt-1 input"
              classNamePrefix="select"
              defaultValue={faculty}
              name="faculty"
              options={dataFaculty}
              onChange={(selectedOption) => setFaculty(selectedOption)}
            />
          </div>
          <div className="my-3">
            <label>Universitas:</label>
            <ReactSelect
              className="basic-single mt-1 input"
              classNamePrefix="select"
              defaultValue={university}
              name="university"
              options={dataUniversity}
              onChange={(selectedOption) => setUniversity(selectedOption)}
            />
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={() => {
                setShow(false);
                getData(100, 1, search);
              }}
            >
              OK
            </div>
            <div
              className="btn bg-blue mx-2 text-white px-4"
              onClick={() => setShow(false)}
            >
              Cancel
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Participant;
